.addTeamMemberButton {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: #315cd5;
    cursor: pointer;
    width: max-content;
    background-color: white;
    border: 2px solid #315cd5;
    border-radius: 5px;
    padding: 0.5rem;
}
.addTeamMemberButton:hover {
    opacity: 0.8;
}

.teamCardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem 0rem;
}

/* Card Container */

.createTeamCard {
    width: 400px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.teamCardFooter {
    display: flex;
    justify-content: end;
    gap: 1rem;
}

.teamCardHeader {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.teamCardHeader img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}

.teamCardTextWrapper {
    word-break: break-all;
    white-space: normal;
}