.App {
  font-family: sans-serif;
  text-align: center;
}

.candidates-capabilities-dialog-mobile .modal-content {
  width: max-content;
}

.capabilities-dialog .modal-content {
  width: calc(100% + 80px);
}