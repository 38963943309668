.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    font-style: normal;
    justify-content: center;
    height: 39px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 11px 16px;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 1em 15px 0px 0px;
    text-transform: uppercase;
    background: rgb(244, 244, 244);
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
    cursor: pointer;
    color: rgb(23, 23, 23);
    outline: none !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: 1px solid rgb(91, 148, 227);
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link:hover{
    color:gray
}

.tab-content{
    padding: 0px 5px !important;
}