body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow-box {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  background: #f9fafc;
  border-radius: 16px;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.shadow-box:hover {
  box-shadow: 0 16px 25px 0 rgba(0, 0, 0, 0.5);
}

.cursor-pointer {
  cursor: pointer;
}

.big-tooltip-bootstrap > .tooltip-inner {
  max-width: 100%;
}

.transition-with-shadow {
  transition: 0.5s ease-in-out;
  box-shadow: 0 0 1px 4px #e25252;
  padding: 3px;
}